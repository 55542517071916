
@font-face {
  font-family: "Campton";
  src: url('./fonts/campton-black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url('./fonts/campton-bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url('./fonts/campton-semibold.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url('./fonts/campton-medium.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url('./fonts/campton-book.otf');
  font-weight: 300;
  font-style: normal;
}



/* Global Styles */



html, body {
  height: 100%;
  max-width: 100%;
  font-family: "Campton", Futura, sans-serif;
  color: #010634;
  -webkit-font-smoothing: antialiased;
  background: #F0F2F9;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.semibold {
  font-weight: 600;
  opacity: 1;
}


/* Progress Bar */



.Progress-container {
  margin: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 440px;
}

.Progress-practice-container {
  background: #C4D4EF;
}

.Progress-bg {
  height: 16px;
  width: 100%;
  background: #DCDDF8;
  border-radius: 8px;
}

.Progress-bar {
  height: 16px;
  border-radius: 8px;
  background: linear-gradient(#8DA4F5, #4A67D2);
  box-shadow: 0px 17.5px 17.5px -8.75px #5470D8, 0px 0px 2px 1px #FFFFFF 20% inset;
  transition: .1s ease-in-out;
}

.Progress-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}



/* Title Page */



.Title {
  background-color: #F0F2F9;
  width: 100%;
  position: absolute;
  top: 0;
}

.Title-bg {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;
}

.Title-content {
  margin: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.close-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 16px 0 0;
}

.close-icon {
  width: 24px;
}

.Title-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Title-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
}

.Title-tag {
  background: #6984E6;
  border-radius: 12px;
  color: #fff;
  padding: 0 14px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 700;
  margin: 16px 0 13px;
  text-transform: uppercase;
}

.Title-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.Title-subtitle {
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  opacity: .8;
  margin: 5px 0 28px;
}



/* Activity Styles */



.Activity {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #F0F2F9;
}

.Activity-content {
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 400px;
}

.Activity-lead {
  color: #7C83C8;
  font-weight: 400;
  margin-bottom: 10px;
}

.Activity-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.Activity-title-highlight {
  color: #7C83C8;
  font-weight: 800;
}

.Activity-title-underline {
  padding-left: 80px;
  border-bottom: 3px solid #010634;
}

.Activity-subtitle {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  opacity: .9;
  margin: 16px 0 28px;
  color: #31355b;
}

.Congrats-title {
  margin: 8px 0;
}

.Congrats-subtitle {
  margin: 0 0 20px;
  max-width: 400px;
  text-align: center;
}

.Activity-image, 
.Activity-image2 {
  max-width: 100%;
  margin-bottom: 22px;
}

.Activity-btn-container {
  margin: 0 16px;
}

.Activity-primary-btn {
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  background: linear-gradient(#535676, #010634);
  box-shadow: 0 16px 16px -8px rgba(1, 6, 52, 0.3), 0 0 0 4px rgba(1, 6, 52, 0.1), inset 0 0 2px 2px rgba(255, 255, 255, 0.4);
  margin: 16px 0;
  flex-shrink: 0;
}


.Activity-secondary-btn {
  color: #2D3158;
  border: 2px solid rgba(45,49,88,.7);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  background: none;
  margin: 4px 0 16px;
  opacity: .8;
}

.Link-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Activity-primary-btn:hover,
.Activity-secondary-btn:hover,
.ToggleImage-button, 
.Feedback-button,
.MultiChoiceGrid-answer-selected,
.Progress-icon-container {
  cursor: pointer;
}


/* Statement */



.Statement {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.Statement-image {
  /*box-shadow: 0px 10px 20px #CCD9F2;*/
  margin: 20px 0;
}

.Statement-secondary-image {
  /*box-shadow: 0px 10px 20px #CCD9F2;*/
  margin: 0 0 16px;
}

.Congrats-image {
  margin: 16px 0;
}


/* Carousel */



.Carousel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.Carousel-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}


.Carousel-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Carousel-caption {
  font-weight: 300;
  margin: 16px 0 0 -50px;
}



/* New Carousel */



.Carousel-container * {
  box-sizing: border-box;
}

.Carousel-container {
  width: 300px;
  text-align: center;
  overflow: hidden;
  margin: 30px 0 16px;
}

.Carousel-container-small {
  width: 200px;
}

.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  
  /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}

.slides::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  opacity: 0;
}
.slides::-webkit-scrollbar-thumb {
  opacity: 0;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}

.Carousel-slide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.Carousel-arrow-left {
  transition: .2s ease-in-out;
  width: 0px;
  height: 0px;
  background: black;
  border-radius: 50%;
  position: absolute; 
  bottom: 46%;
  left: -40px;
  z-index: 10;
  display: none;
  opacity: 0
}

.Carousel-arrow-right {
  transition: .2s ease-in-out;
  width: 0px;
  height: 0px;
  background: black;
  border-radius: 50%;
  position: absolute; 
  bottom: 46%;
  right: -40px;
  z-index: 10;
  display: none;
  opacity: 0;
}

.Carousel-arrow-appear {
  width: 40px;
  height: 40px;
  bottom: 43%;
  opacity: 1;
}

.Carousel-icon {
  transition: .2s ease-in-out;
  width: 0px;
  height: 0px;
  opacity: 0;
}

.Carousel-icon-appear {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.Carousel-arrow-left:hover,
.Carousel-arrow-right:hover {
  cursor: pointer;
}

.Carousel-slide {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 300px;
  margin-right: 50px;
  border-radius: 5px;
  background: #eee;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.Carousel-slide-small {
  width: 200px;
}

.author-info {
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
.author-info a {
  color: white;
}

.Carousel-image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Carousel-dot {
  transition: .25s ease-in;
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}

.Carousel-dots {
  display: flex;
  margin-top: 6px;
}

.Carousel-dot {
  background: rgba(131,139,166,.36);
  height: 14px;
  width: 14px;
  border-radius: 8px;
  margin: 6px;
}

.Carousel-dot:hover {
  cursor: pointer;
}

.Carousel-current-dot {
  background: #6E88E5;
}



/* Don't need button navigation */
@supports (scroll-snap-type) {
  .Carousel-container > a {
    display: none;
  }
}

/*
.Carousel-container::before,
.Carousel-container::after,
.Carousel-container__prev,
.Carousel-container__next {
  position: absolute;
  top: 0;
  margin-top: 37.5%;
  width: 4rem;
  height: 4rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.Carousel-container::before,
.Carousel-container__prev {
  left: -1rem;
}

.Carousel-container::after,
.Carousel-container__next {
  right: -1rem;
}

.Carousel-container::before,
.Carousel-container::after {
  content: '';
  z-index: 1;
  background-color: #333;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.Carousel-container::before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
}

.Carousel-container::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
}
*/




/* Question */




.Question {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}




/* ClickImage */



.ClickImages {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: 0px 20px 40px 0px #CECFD6;
  margin-bottom: 20px;
  border-radius: 16px;
}

.ClickImage {
  max-width: 100%;
  margin-top: -1px;
}


.ClickImages:hover {
  cursor: pointer;
}



/* MultiChoice */




.MultiChoice-answers {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.MultiChoice-answer {
  background: #fff;
  box-shadow: 0px 10px 10px -10px #D4D4D4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin: 8px;
  color: #34385D;
  line-height: 1.5;
  text-align: center;
}

.MultiChoice-image-answer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  box-shadow: 0 10px 20px 0 #CCD9F2;
  border-radius: 12px;
}

.MultiChoiceGrid,
.MultiChoiceStack {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  color: #34385D;
  margin-bottom: 12px;
}

.MultiChoiceGrid-row {
  display: flex;
  width: 100%;
}

.MultiChoiceGrid-answer {
  width: 50%;
  height: 78px;
  border: 2px solid #fff;
}

.MultiChoiceGrid-answer-selected {
  width: 50%;
  height: 78px;
  background: #EEF2FF;
  border: 2px solid #889FF1;
}

.MultiChoiceGridAnswer-icon {
  margin-right: 8px
}

.MultiChoiceStack-answer {
  width: 100%;
  padding: 16px 0;
  margin: 8px 0;
  border: 2px solid #fff;
}

.MultiChoiceStack-answer-selected {
  background: #EEF2FF;
  width: 100%;
  padding: 16px 0;
  margin: 8px 0;
  border: 2px solid #889FF1;
}

.MultiChoiceStackImage-answer-selected {
  box-shadow: 0 12px 12px 0 #BBBED2;
}


.MultiChoice-answer-text {
  margin: 0px 16px;
}

.MultiChoiceGrid-answer:hover,
.MultiChoiceStack-answer {
  cursor: pointer;
}

.center-text {
  font-size: 21px;
  margin: 0 0 12px;
}

.MultiChoice-image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.MultiChoice-image {
  max-width: 100%;
  border-radius: 12px;
}

.MultiChoice-image-placeholder {
  width: 400px;
  max-width: 100%;
  border-radius: 12px;
  background: #fff;
  height: 150px;
}

.MultiChoice-text-image {
  max-width: 90%;
  margin-top: 12px;
}

.MultiChoice-image-container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MultiChoice-image2 {
  margin-right: 12px;
  max-width: 70%;
}



/* Toggle Image */



.ToggleImage {
  max-width: 100%;
  box-shadow: 0px 6px 10px 0 #D4D4D4;
  margin: 24px 0 16px;
}

.ToggleImage-button {
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 100%;
  border-radius: 10px;  
  background: linear-gradient(#8da4f5, #4a67d2);
  box-shadow: 0 8px 18px 9px rgba(84,112,216,.25), inset 0 0 2px 2px rgba(255,255,255,.1);
  margin: 16px 0 28px;
}

.ToggleImage-button-icon {
  margin-right: 8px;
}


/* Feedback */



.Feedback-container {
  position: fixed;
  bottom: -110px;
  z-index: 20;
  width: 100%;
}

.Feedback {
  background-color: #B7F0AE;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Feedback-message-container {
  text-align: left;
  line-height: 1.4;
  width: 100%;
}

.Feedback-message {
  margin: 16px 16px 0;
  display: flex;
  align-items: center;
}

.Feedback-message-icon {
  margin-right: 16px;
}

.Feedback-button-container {
  width: 100%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Feedback-button {
  height: 54px;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(180deg, #63EE79 0%, #0BAA24 100%);
  box-shadow: 0px 17.5px 17.5px -8.75px rgba(21,62,0,0.3), 0px 0px 2px 2px rgba(255,255,255,0.1) inset;
  color: #fff;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.Feedback-button-content {
  display: flex;
  align-items: flex-start;
  line-height: 33px;
  font-size: 18px;
}
.Feedback-button-content-text {
  margin: -2px 0 0;
}

.Feedback-button-content-icon {
  margin: 6px 0 0 8px;
  box-shadow: 0px 4px 4px rgba(126, 66, 11, 0.2);
  border-radius: 50%;
}

.Feedback-incorrect {
  background-color: #FBCFA6;
}

.Feedback-button-incorrect {
  background: linear-gradient(180deg, #EB984B 0%, #C26A19 100%);
  box-shadow: 0px 17.5px 17.5px -8.75px rgba(194,25,66,0.3), 0px 0px 2px 2px rgba(255,255,255,0.1) inset;
}

.Feedback-incorrect2 {
  background-color: #FBA6A6;
}

.Feedback-button-incorrect2 {
  background: linear-gradient(180deg, #EB4B72 0%, #C21942 100%);
  box-shadow: 0px 17.5px 17.5px -8.75px rgba(194,25,66,0.3), 0px 0px 2px 2px rgba(255,255,255,0.3) inset;
}

.Feedback-neutral {
  background-color: #fff;
}

@keyframes feedback-entrance {
  from {bottom: -110px;}
  to {bottom: 0;}
}

.Feedback-entrance {
  animation-name: feedback-entrance;
  animation-duration: .15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes feedback-exit {
  from {bottom: 0;}
  to {bottom: -110px;}
}

.Feedback-exit {
  animation-name: feedback-exit;
  animation-duration: .1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.Feedback-image {
  max-width: 100%;
}


/* LabStatement */


.LabStatement-image {
  max-width: 100%;
  margin-bottom: 24px;
}

.LabStatement-text {
  margin-top: 0;
  line-height: 1.45;
}


/* StyleChange */


.StyleChange-object-container {
  background-color: #fff;
  padding: 1px 0;
  margin: 0 8px 20px;
  border-radius: 8px;
}

.StyleChange-object {
  margin: 30px;
  color: rgba(0,0,0,.7);
  line-height: 1.4;
}

.objectOneOne {
  border-radius: 0;
  height: 190px;
  box-shadow: 0px 30px 50px #D9DAE0;
}

.objectOneOne-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition: .3s;
  transition-timing-function: ease-in-out;
}

.objectOneOne-content-fix {
  justify-content: space-between;
  margin-top: 10px;
}

.objectOneOne-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 36%;
  margin: 0 8px;
}

.objectOneOne-content-item-container {
  background: #DDEAF3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 78px;
  transition: .3s;
  transition-timing-function: ease-in-out;
}

.objectOneOne-depth-fix {
  background: #BFD4E4;
  box-shadow: 0px 15px 20px -16px rgba(92, 185, 221, 0.3);
}

.objectOneOne-color-fix {
  background: #32C5FF;
}

.objectOneOne-size-fix {
  width: 100%;
  margin-bottom: 16px;
}

.StyleChange-image-container {
  position: relative;
}

.StyleChange-image {
  position: relative;
  max-width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 8px;
}

.objectText {
  position: absolute;
  z-index: 5;
  bottom: 28%;
  width: 83%;
  left: 9%;
}

.StyleChange-img-zero {
  max-width: 100%;
  margin-top: -2px;
}

.StyleChange-img-one {
  max-width: 23%;
  transition: .3s;
  transition-timing-function: ease-in-out;
  margin-left: 16px;
  opacity: 1;
}

.StyleChange-img-one-size-fix {
  max-width: 14%;
}

.StyleChange-img-one-depth-fix {
  opacity: .7;
}

.StyleChange-img-two {
  max-width: 52%;
  margin: 12px auto;
}

.StyleChange-img-three {
  max-width: 16%;
  transition: .3s;
  transition-timing-function: ease-in-out;
  margin-right: 16px;
  opacity: 1;
}

.StyleChange-img-three-size-fix {
  max-width: 14%;
}

.StyleChange-img-three-depth-fix {
  opacity: .7;
}

.StyleChange-img-four {
  max-width: 100%;
  opacity: .5;
  transition: .3s;
  transition-timing-function: ease-in-out;
}

.StyleChange-img-four-depth-fix {
  filter: drop-shadow(0px 5px 5px rgba(101, 126, 145, 0.1));
  opacity: 1;
}

.StyleChange-img-one-fix {
  font-size: 16px;
}

.StyleChange-heading-resize {
  transition: .2s ease-in-out;
  line-height: 1.2;
  font-weight: 600;
  color: #222;
  margin: 0 0 16px;
}

.StyleChange-subheading-resize {
  transition: .2s ease-in-out;
  line-height: 1.2;
  margin: 0 0 16px;
  font-weight: 300;
}

.StyleChange-text-resize {
  transition: .2s ease-in-out;
  line-height: 1.5;
  font-weight: 300;
}
.StyleChange-heading {
  transition: .2s ease-in-out;
  line-height: 22.4px;
  margin: 16px 0;
  font-weight: 400;
}

.StyleChange-heading1 {
  font-size: 32px;
  line-height: 22.4px;
  margin: 0 0 16px;
}

.StyleChange-heading2 {
  line-height: 36px;
}

.StyleChange-heading3 {
  font-weight: 700;
}

.StyleChange-heading4 {
  color: #000;
}

.StyleChange-text {
  margin-bottom: 0;
}

.objectFiveOne {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.StyleChange-h2 {
  margin: 20px auto 0;
}

.StyleChange-button2 {
  transition: .2s ease-in-out;
  background: #FF764A;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(141, 40, 8, 0.3);
  width: 166px;
}

.StyleChange-button-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin: 0 0 0 8px;
}



/* Overview */



.Overview-content {
  padding: 90px 0 20px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
  
.OverviewCard {
  width: 100%;
  background: #FBFBFD;
  box-shadow: 0px 10px 20px #CCD9F2;
  border-radius: 12px;
  margin: 16px 0;
}

.OverviewCard-image {
  width: 100%;
  height: 120px;
  background-color: gray;
  border-radius: 8px 8px 0 0;
}

.OverviewCard-content {
  margin: 16px;
}



/* Path */



.Path-navbar {
  height: 75px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(1, 6, 52, 0.1);
  position: fixed;
  z-index: 100;
}

.Path-navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  height: 75px;
}

.Path-navbar-logo {
  margin-bottom: 4px;
}

.Path-navbar-button {
  background: linear-gradient(180deg, #535676 0%, #010634 100%);
  box-shadow: inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  color: #fff;
  line-height: 48px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
}

.Path-divider {
  margin: 0 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
}

.Path-divider-outline {
  height: 40px;
  width: 120px;
  border-radius: 40px;
  border: 2px solid #CCD9F2;
  background: #F0F2F9;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.Path-divider-content {
  height: 32px;
  width: 113px;
  border-radius: 30px;
  color: #fff;
  font-weight: 700;
  font-size: 13.5px;
  background: linear-gradient(#535676, #010634);
  box-shadow:  0px 0px 2px 0px rgba(255,255,255,0.4) inset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Path-divider-line {
  background: linear-gradient(to right, #F0F2F9, #CCD9F2);
  height: 2px;
  width: 100%;
  max-width: 320px;
}

.Path-divider-line-two {
  background: linear-gradient(to left, #F0F2F9, #CCD9F2);
}

.Path-section1 {
  background: url(./images/Path1bg.svg) center top no-repeat;
  height: 1137px;
  margin-bottom: 50px;
}

.Path-section2 {
  background: url(./images/Path2bg.svg) center top no-repeat;
  height: 1129px;
  margin-bottom: 50px;
}

.Path-section3 {
  background: url(./images/Path3bg.svg) center top no-repeat;
  height: 1152px;
}

.LabPath {
  background: url(./images/labPath.svg) center top no-repeat;
  height: 868px;
}

.PathItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  text-align: center;
  font-weight: 600;
  position: absolute;
  left: 50%;
}

.PathItem-activity-icon {

}

.PathItem:hover {
  cursor: pointer;
}

.PathItem-locked {
  color: #A0A3BA;
  margin: -12px 0 16px;
}

.PathItem-unlocked {
  color: #010634;
  margin: -12px 0 16px;
}

.PathItem-complete {
  color: #010634;
  margin: -12px 0 16px;
}

.PathItem-project {
  margin: 0;
}

.PathItem-project-heading {
  font-size: 24px;
  font-weight: 600;
}

.PathItem-project-text {
  font-size: 12px;
  font-weight: 300;
}

.PathItem-icon {
  position: absolute;
  transform: translate(-47px, -12px);
}

.Project-icon {
  position: absolute;
  transform: translate(-105px, -18px);
}

.PathItem-pulsate {
  animation: pulsate 1s alternate ease-in infinite;
  transform: scale(1);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.07);
  }
}

.coming-soon-outline {
  width: 298px;
  border: 2px solid #777A99;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 50px;
}

.coming-soon {
  width: 290px;
  background: #010634;
  color: #fff;
  border-radius: 12px;
  margin: 4px 0;
}

.coming-soon-text1 {
  margin: 18px 0 4px;
  opacity: .6;
  font-size: 14px;
}

.coming-soon-text2 {
  margin: 0 0 18px;
  font-size: 24px;
}

.Path-shadow {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  opacity: .5;
  background: #000;
  height: 2500px;
}














.Nav {
  background-color: #6440F4;
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 20;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .15);
}

/* New Instructions */


.Instructions-container {
  left: 0;
  top: 50px;
  position: absolute;
  overflow-x: hidden;
  width: 100%;
}

.Instructions-row {
  display: flex;
  width: 200vw;
  transform: translateX(0);
}

.Instructions-slideout {
  animation-name: instructions-exit;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  overflow-x: visible;
}

@-webkit-keyframes instructions-exit {
  from {transform: translateX(0);}
  to {transform: translateX(-100vw);}
}

@-moz-keyframes instructions-exit {
  from {left: 0;}
  to {left: -100vw;}
}

@-ms-keyframes instructions-exit {
  from {left: 0;}
  to {left: -100vw;}
}

@keyframes instructions-exit {
  from {transform: translateX(0);}
  to {transform: translateX(-100vw);}
}

.Instructions {
  background-color: #fff;
  width: 100%;
  margin: 12px 16px 24px;
  border-radius: 12px;
  top: 0;
  z-index: 10;
  padding: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  text-align: left;
}

.hidden {
  opacity: 0;
}



.Instructions-content {
  margin: 10px 30px;
  line-height: 1.5;
  font-size: 18px;
  min-width: 240px;
  max-width: 400px;
}

.Instructions-content-text {
  margin: 0;
}

.Instructions-content-example {
  background-color: #F0F3F9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 8px;
}

.gone {
  display: none;
}



/* Instructions 



.Instructions-container {
  display: flex;
  width: 100%;
  left: 0;
  top: 76px;
  position: absolute;
  overflow-x: hidden;
}

.Instructions-slide {
  width: 100%;
  display: flex;
}

.Instructions {
  background-color: #fff;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  top: 0;
  z-index: 10;
  padding: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  text-align: center;
}

.hidden {
  opacity: 0;
}

.gone {
  display: none;
}

.Instructions-content {
  margin: 10px 30px;
  line-height: 1.5;
  font-size: 18px;
  min-width: 240px;
  max-width: 400px;
}

.Instructions-content-text {
  margin: 0;
}

.Instructions-content-example {
  background-color: #F0F3F9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 8px;
}

@-webkit-keyframes instructions-exit {
  from {left: 0;}
  to {left: -100vw;}
}

@-moz-keyframes instructions-exit {
  from {left: 0;}
  to {left: -100vw;}
}

@-ms-keyframes instructions-exit {
  from {left: 0;}
  to {left: -100vw;}
}

@keyframes instructions-exit {
  from {left: 0;}
  to {left: -100vw;}
}

.Instructions-slideout {
  animation-name: instructions-exit;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  overflow-x: visible;
  width: 200%;
}

*/



/*
@-webkit-keyframes instructions-entrance {
  from {opacity: 0;}
  to {opacity: 100%;}
}

@-moz-keyframes instructions-entrance {
  from {opacity: 0;}
  to {opacity: 100%;}
}

@-ms-keyframes instructions-entrance {
  from {opacity: 0;}
  to {opacity: 100%;}
}

@keyframes instructions-entrance {
  from {opacity: 0;}
  to {opacity: 100%;}
}

.Instructions-slidein {
  animation: instructions-entrance .2s forwards ease-in-out;
  -webkit-animation: instructions-entrance .2s forwards ease-in-out;
  -moz-animation: instructions-entrance .2s forwards ease-in-out;
  -ms-animation: instructions-entrance .2s forwards ease-in-out;
  opacity: 1;
}*/

.LessonActivity {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
}

.LessonActivity-question {
  margin: 0 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow:  0 8px 20px rgba(154,148,175,.2);
}

.LessonActivity-title {
  font-size: 21px;
  font-weight: 500;
  padding: 30px 20px;
  line-height: 1.4;
  color: #333;
  max-width: 100%;
}

.LessonActivity-title-highlight {
  font-weight: 700;
  color: #6440F4;
}

.LessonActivity-title-highlight-blue {
  font-weight: 700;
  color: #1555FB;
}

.LessonActivity-answers {
  margin: 0 30px;
}

.LessonActivity-answer-image-container {
  text-align: center;
  margin-top: 20px;
}

.LessonActivity-answer-image, .LessonIntro-image {
  max-width: 100%;
}

.LessonActivity-image {
  max-width: 100%;
  margin-top: 30px;
}

.LessonActivity-button {
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.LessonActivity-button-standard {
  background-color: #fff;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:  0 8px 20px rgba(154,148,175,.2);
  margin: 30px 0;
  color: #333;
}

.LessonActivity-button-text {
  max-width: 80%;
  text-align: center;
  line-height: 1.4;
  font-size: 18px;
  font-weight: 400;
}

.full-screen {
  height: 100%;
}




.LessonIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  height: 100%;
}

.Lesson-type {
  margin-top: 30px;
  opacity: .6;
}
.Lesson-title {
  max-width: 300px;
  margin: 0 auto 40px;
}

.PrimaryButton {
  color: #fff;
  background-color: #6440F4;
  height: 55px;
  line-height: 55px;
  border-radius: 28px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(85, 65, 163, .3);
  position: absolute;
  bottom: 30px;
}

.PrimaryButton:hover,
.SecondaryButton:hover,
.LessonActivity-button:hover,
.LessonActivity-button-standard:hover,
.MultiChoice-image:hover,
.LessonActivity-answer-image:hover, 
.MultiChoice-image-answer:hover,
.Feedback {
  cursor: pointer;
}

.SecondaryButton {
  color: #3D3D3D;
  background-color: #fff;
  height: 56px;
  line-height: 55px;
  border-radius: 28px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(85, 65, 163, .3);
  position: absolute;
  bottom: 30px;
}

.Object-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 220px 0 0;
}

.Object {
  width: 200px;
  height: 55px;
  border: 2px solid #4E5C6A;
  color: rgba(0, 0, 0, .8);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin: 0 auto 0;
}

.DemoForm-button {
  height: 46px;
  width: 216px;
}

.Object-input {
  width: 192px;
  height: 44px;
  border: 2px solid #4E5C6A;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  margin: 0 auto 0;
  padding: 0 12px;
}

.LabExample-button2 {
  border-radius: 6px;
  color: #fff;
  background-color: #361AA3;
  border: 2px solid #361AA3;
}

.Object:hover {
  cursor: pointer;
}

.Artboard-background {
  background: #C4D4EF;
  width: 100%;
  display: flex;
  justify-content: center;

}

.Artboard-mobile {
  background: #fff;
  box-shadow: 0px 23px 35px rgba(42, 67, 109, 0.23);
  margin: 155px 16px 230px;
  border-radius: 20px;
  height: 750px;
  max-width: 340px;
}

.Artboard-oneTwo {
  background: linear-gradient(#162C49,#102541);
  height: 600px;
}

.Artboard {
  background-color: #fff;
  margin: 175px 16px 200px;
  width: 100%;
}

.Artboard-content {
  margin: 16px;
}

.Artboard-content-unselected {
  border: 1px solid rgba(0,0,0,0);
}

.Artboard-project-content {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.oneTwo-image-one {
  max-width: 100%;
}

.oneTwo-image-three {
  box-shadow: 0px 29px 37px rgba(34, 1, 83, 0.29)
}

.oneTwo-image-two {
  max-width: 100%;
  box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.Artboard-project-logo {
  max-width: 120px;
  margin: 10px 0 0;
}

.Artboard-project-subtitle {
  max-width: 100%;
}

.Artboard-project-image {
  max-width: 30%;
}

.LabArtboard-button-icon {
  margin-right: 6px
}

.LabArtboardInput-line {
  height: 2px;
  background: rgb(240, 242, 249);
  width: 0;
  position: absolute;
  transform: translate(-4px, -23px);
  transition: .12s ease-in-out;
}

.LabArtboardInput-line-expand {
  width: 45px;
}

.LabArtboardForm {
  width: 222px;
  margin: 150px auto 0;
}

.LabArtboardForm-message {
  font-size: 13px;
  margin: 6px 0 20px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.LabArtboardForm-message:hover {
  cursor: pointer;
}

.purple-text {
  color: #5672D9;
}

.icon-white {
  width: 20px;
  height: 20px;
}

.Artboard-project-button {
  /*
  width: 200px;
  height: 55px;
  border: 2px solid #4E5C6A;
  color: rgba(0, 0, 0, .8);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin: auto;*/
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.Artboard-project-button:hover,
.Artboard-text:hover,
.Artboard-image:hover {
  cursor: pointer;
}


.Artboard-one-four {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 12px 20px 0 #CCD9F2;
}

.Artboard-content-one-two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Artboard-content-one-four {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  width: 100%;
  max-width: 220px;
  margin: 20px auto 0;
  background: linear-gradient(#E8216D, #C0064D);
  padding: 20px;
  border: 8px solid black;
  border-bottom: none;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
}

.heading-one-four {
  margin: 0;
}

.text-one-four {
  margin: 12px 0 24px;
  font-weight: 300;
}

.button-one-four {
  width: 120px;
  margin-bottom: 8px;
}

.Artboard-text {
  margin: 16px 0;
}

.Artboard-image {
  margin: auto;
}


.Artboard-button {
  border: 2px solid gray;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}

.Artboard-button:hover {
  cursor: pointer;
}

.prompt {
  animation: float 1s alternate ease-in infinite;
  transform: translateY(0);
  position: fixed;
  bottom: 100px;
  left: 50vw;
  width: 200px;
  margin-left: -112px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 4px 10px rgba(85, 65, 163, .1);
  color: #444;
}

.prompt:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 45%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 10px solid #fff;
  border-right: 8px solid transparent;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(6px);
  }
}

.Object-edit {
  position: absolute;
}

.Object-edit-input {
  width: 192px;
  height: 44px;
  font-weight: 400;
  padding: 0 12px;
  transition: .2s ease-in-out;
}

.Object-edit-button {
  width: 216px;
  height: 46px;
}

.Example {
  margin: 16px 0;
}

.Toolbar {
  background-color: #34333A;
  height: 75px;
  width: 100%;
  position: fixed;
  bottom: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes toolbar-entrance {
  from {bottom: -80px;}
  to {bottom: 0;}
}

.Toolbar-appear {
  animation-name: toolbar-entrance;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.Toolbar-container {
  display: flex;
  width: 100%;
  max-width: 415px;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  margin: 0 2%;
}

.Tool {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Tool:hover {
  cursor: pointer;
}

.Tool-label {
  margin: 5px 0 0;
  font-size: 12px;
}

.Fill {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  width: 310px;
  position: fixed;
  bottom: 90px;
  left: 50%;
  margin-left: -155px;
}

.Fill-dark {
  background-color: #333;
}

.Fill:hover {
  cursor: pointer;
}

.Fill-row {
  display: flex;
  width: 294px;
  max-width: 415px;
  align-items: center;
  margin: 8px auto;
  height: 42px;
}

.space-around {
  justify-content: space-around;
}

.Color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
}

.StrokeColor {
  width: 26px;
  height: 26px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
}

.selected {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background-color: #E9ECF1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-dark {
  width: 42px;
  height: 42px;
  background-color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.LabAnimateOption {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Shadow {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  width: 52px;
  padding: 20px;
  position: fixed;
  bottom: 90px;
  left: 50%;
  margin-left: -56px;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-container:hover {
  cursor: pointer;
}

.toggle-btn {
  width: 28px;
  height: 28px;
  border: 1px solid #E9ECF0;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
  position: absolute;
  z-index: 2;
}

.toggle-btn-on {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #4688D3;
  left: 40px;
}

.toggle-bg {
  width: 50px;
  height: 23px;
  border-radius: 11px;
  background-color: #D6DDE4;
  position: relative;
  z-index: 1;
}

.toggle-bg-on {
  background-color: #4688D3;
  opacity: .5;
}

.gray-shadow {
  box-shadow: 0 4px 10px rgba(80, 80, 80, .1);
}

.purple-shadow {
  box-shadow: 0 8px 18px rgba(42, 25, 112, .3);
}

.salmon-shadow {
  box-shadow: 0px 4px 10px rgba(114, 56, 81, 0.3);
}

.r-8 {
  border-radius: 8px;
}

/* Slider */




.slider-container {
  display: flex;
  align-items: center;
}

.slider-value {
  background-color: #E0E7EE;
  height: 30px;
  width: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 16px;
}

.Slider-top-row {
  margin-bottom: -12px;
}

.slider {
  -webkit-appearance: none;  
  appearance: none;
  width: 80%; 
  height: 8px; 
  background: #E2E2EB; 
  outline: none; 
  opacity: 0.9; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 4px;
  margin-right: 8px
}

.Slider-double {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Slider-double-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.slider-icon {
  margin: 0 11px 0 -1px;
}

.slider-icon2 {
  margin: 0 14px 0 2px;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 60%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 60%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}


/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: #272B52;
  cursor: pointer;
  margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: #272B52;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: #272B52;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 60%;
  height: 8px;
  cursor: pointer;
  background: #E0E7EE;
  border-radius: 4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #E0E7EE;
}

input[type=range]::-moz-range-track {
  width: 60%;
  height: 8px;
  cursor: pointer;
  background: #E0E7EE;
  border-radius: 4px;
}

input[type=range]::-ms-track {
  width: 60%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #E0E7EE;
  border-radius: 4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #E0E7EE;
}
input[type=range]::-ms-fill-upper {
  background: #E0E7EE;
  border-radius: 4px;
}
input[type=range]:focus::-ms-fill-upper {
  background: #E0E7EE;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



 

/* Demos */



.DemoButton,
.DemoForm {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DemoButton-state {
  font-size: 20px;
  font-weight: 500;
  opacity: .8;
}

.success-message {
  display: flex;
  align-items: center;
}

.success,
.default {
  transition: .2s ease-in-out;
}

.message-visible {
  font-size: 18px;
}

.message-invisible {
  font-size: 0;
}

.success-icon-visible {
  width: 17px;
  margin-right: 6px;
}

.loading-icon {
  margin-right: 6px;
}

.success-icon-invisible {
  width: 0;
}

.DemoForm-input {
  font-family: "Campton", "Futura", sans-serif;
  width: 200px;
  height: 44px;
  border: 2px solid #C5CFD8;
  border-radius: 8px;
  color: rgba(0, 0, 0, .8);
  font-size: 18px;
  font-weight: 400;
  padding: 0 8px;
  outline: rgba(0, 0, 0, 0);
  transition: .2s ease-in-out;
}

.DemoForm-input:focus {
  border: 2px solid #6440F4;
}

.DemoForm-input-error {
  border: 2px solid #C4505E;
}


.DemoForm-input-label {
  position: absolute;
  transform: translate(-71px, -6px);
  font-size: 18px;
  color: #7B8895;
  transition: .2s ease-in-out;
}

.DemoForm-input-label-raised {
  transform: translate(-77px, -23px);
  color: #7B8895;
  font-size: 13px;
}

.DemoForm-input:focus ~ .DemoForm-input-label {
  color: #6440F4;
  transform: translate(-77px, -23px);
  font-size: 13px;
}

.DemoForm-input-line {
  height: 2px;
  background: rgb(240, 242, 249);
  width: 0;
  position: absolute;
  transform: translate(-77px, 0);
  transition: .12s ease-in-out;
}

.DemoForm-input:focus ~ .DemoForm-input-line {
  width: 45px;
}

.DemoForm-input-line-expanded {
  width: 45px;
}

.DemoForm-input-error-container {
  width: 216px;
  transition: .2s ease-in-out;
}

.DemoForm-input-error-message {
  color: #C4505E;
  font-size: 13px;
  margin: 8px 0 0;
}

/* Pairs */

.PairGrid {
  display: flex;
  flex-wrap: wrap;
}

.PairGridAnswer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
}

.PairGridAnswer-button {
  background-color: #CC1F68;
  border-radius: 8px;
  height: 46px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;

}

.demo-button-pressed {
  background-color: #98164D;
  box-shadow: none;
}

.demo-button-default {
  box-shadow: 0px 4px 10px rgba(114, 56, 81, 0.3);
}

.PairGridAnswer-text {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(118, 131, 157, 0.29);
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
}

.PairGridAnswer-selected {
  border: 4px solid #7DD8FF;
}

.PairGridAnswer-correct {
  border: 4px solid #63EE79;
}

.PairGridAnswer-incorrect {
  border: 4px solid red;
}

.PairGridAnswer-default {
  border: 4px solid rgba(0, 0, 0, 0);
}

.PairGridAnswer-default:hover {
  cursor: pointer;
}

.PairGridAnswer-finished {
  opacity: .2;
  border: 4px solid rgba(0, 0, 0, 0);
}


.blinking {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to {opacity: 0;}
  50% {opacity: 1;}
}

.hidden {
  visibility: hidden;
}

/* Colors & Strokes */

.full-radius {
  border-radius: 30px;
}

.no-color {
  background-color: none;
}

.no-color-icon {
  background-color: none;
  border: 2px solid #C4505E;
  width: 26px;
  height: 26px;
}

.white {
  background-color: #fff;
  color: #242424;
}

.white-icon {
  background-color: #fff;
  border: 2px solid #C5CFD8;
  width: 26px;
  height: 26px;
}

.gray1 {
  background-color: #C5CFD8;
  color: #242424;
}

.gray2 {
  background-color: #7B8895;
  color: #fff;
}

.gray3 {
  background-color: #4E5C6A;
  color: #fff;
}

.black {
  background-color: #000;
  color: #fff;
}

.green2 {
  background-color: #3C7669;
  color: #fff;
}

.yellow {
  background-color: #F0C44C;
  color: #242424;
}

.orange {
  background-color: #DB874A;
  color: #fff;
}

.red {
  background-color: #C4505E;
  color: #fff;
}

.pink {
  background-color: #AE46CA;
  color: #fff;
}

.pink2 {
  background-color: #CC1F68;
}

.pink3 {
  background-color: #98164D;
}

.purple0 {
  background-color: #CCBFFF;
  color: rgba(0, 0, 0, .8);
}

.purple1 {
  background-color: #957CF8;
  color: #fff;
}

.purple {
  background-color: #6440F4;
  color: #fff;
}

.purple-dark {
  background-color: #361AA3;
  color: #fff;
}

.blue {
  background-color: #4688D3;
  color: #fff;
}

.salmon {
  background-color: #CC1F68;
  color: #fff;
}

.salmon-dark {
  background-color: #98164D;
  color: #fff;
}

.green1 {
  background-color: #51AB9F;
  color: #fff;
}

.green3 {
  background-color: #50A491;
  color: #fff;
}

.no-border-icon {
  border: 2px solid #C4505E;

}

.white-border-icon {
  border: 2px solid rgb(225, 230, 235);
}

.no-border {
  border: 2px solid rgba(0, 0, 0, 0);
}

.white-border {
  border: 2px solid #fff;
}

.gray1-border {
  border: 2px solid #C5CFD8;
}

.gray2-border {
  border: 2px solid #7B8895;
  color: #4E5C6A;
}

.gray3-border {
  border: 2px solid #4E5C6A;
}

.black-border {
  border: 2px solid #000;
}

.green2-border {
  border: 2px solid #3C7669;
}

.yellow-border {
  border: 2px solid #F0C44C;
}

.orange-border {
  border: 2px solid #DB874A;
}

.red-border {
  border: 2px solid #C4505E;
}

.pink-border {
  border: 2px solid #AE46CA;
}

.purple0-border {
  border: 2px solid #CCBFFF;
}

.purple1-border {
  border: 2px solid #957CF8;
}

.purple-border {
  border: 2px solid #6440F4;
}

.purple-dark-border {
  border: 2px solid #361AA3;;
}

.blue-border {
  border: 2px solid #4688D3;
}

.green1-border {
  border: 2px solid #51AB9F;
}

.green3-border {
  border: 2px solid #50A491;
}

.radius-6 {
  border-radius: 6px;
}

.oneA8a {
  border: 2px solid #6440F4;
  background-color: none;
  color: #6440F4;
}

.oneA8b {
  background-color: #D4CAFF;
  color: #3F2A95;
}

.oneA8c {
  background-color: #35247A;
  color: #fff;
}

.hide {
  display: none;
}

.offscreen {
  position: absolute; 
  left: -5000px;
}

.gray2-text {
  color: #7B8895;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-left {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end
}

.overline {
  transition: .12s ease-in-out;
  transform: translate(-1px, 0);
}

.overline-active {
  font-size: 13px;
  color: #6440F4;
  transform: translate(-1px, -25px);
}

.LabArtboardInput-label {
  position: absolute;
  margin-left: 2px;
  font-size: 13px;
  color: #919EAA;
  transform: translate(-1px, -25px);
  transition: .2s ease-in-out;
}

.red-text {
  color: #C4505E;
}

.m-0 {
  margin: 0
}

.pt-150 {
  padding-top: 150px;
}


.Artboard-content-selected {
  border: 1px solid #18A0FB;
}

.Artboard-content-input-selected {
  padding: 6px 0 0;
  border: 1px solid #18A0FB;
}



/* Demo Media Queries */


@media only screen and (min-width: 500px) {
  .Instructions {
    width: 400px;
    border-radius: 12px;
    margin: 30px auto 24px;
    text-align: left;
    padding: 10px 0;
  }
  .Progress-container {
    padding: 30px 10%;
    max-width: 100%;
  }
  .Artboard {
    margin-top: 210px;
    max-width: 480px;
  }
  .LessonActivity {
    position: static;
    margin: 30px auto;
  }
  .Object-container {
    margin: 280px auto 0;
  }
  .Activity-btn-container {
    width: 500px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .Title-btn {
    width: 300px;
  }
  .Activity-content {
    margin: auto;
  }
  .Feedback-message-container {
    max-width: 400px;
    margin: 6px 0 0;
  }
  .Feedback-button-container {
    max-width: 400px;
    margin: 6px 0;
  }
  .Title-bg {
    display: none;
  }
  .Carousel-arrow-left,
  .Carousel-arrow-right {
    display: block;
  }
  .Carousel-container,
  .Carousel-slide {
    width: 400px;
  }
  .Carousel-container-small,
  .Carousel-slide-small {
    width: 300px;
  }
}

@media only screen and (min-width: 433px) {
  .Activity-content {
    margin: auto;
  }
  .Progress-container {
    max-width: 100%;
  }
}

@media only screen and (min-width: 390px) {
  .objectOneOne {
    height: 222px;
  }
}

@media only screen and (min-width: 360px) {
  .Artboard-mobile {
    width: 340px;
  }
}

@media only screen and (max-width: 332px) {
  .Instructions {
    width: 288px;
    margin: 12px auto 24px;
  }
}